#app-sidebar .side {
    background-color: #384EA1
}

#app-sidebar .side .item:hover {
    background-color: #5668AF
}

.ui.inverted.menu .active.item {
    background-color: #5298f29c !important
}

#app-sidebar .side .item {
    border: none;
}

.table-actions .ui.button {
    background-color: #384EA1;
    color: whitesmoke
}

.table-actions .ui.button:hover {
    background-color: #1a3ab3;
    color: whitesmoke
}

.table-actions .ui.button.active {
    background-color: #1a3ab3 !important;
}

.table-actions .ui.button.active.visible {
    background-color: #1a3ab3 !important;
    color: whitesmoke
}
#login-section {
    width: 950px;
    height: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center
}

#login-section .photo-section {
    width: 58%;
}

#login-section .login-section {
    width: 40%
}

.login-button:hover {
    background-color: rgb(49, 83, 189) !important;
}
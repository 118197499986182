.plan-title {
    font-size: 25px;
    text-align: center;
}

.feature {
    border: none !important;
    font-size: 25px;
    display: flex;
    justify-content: left;
}

.feature i {
    margin-right: 2rem!important;
}

.price {
    text-align: center;
}

.feature .regular-price {
    position: absolute;
    top: 5px;
    right: 49%;
}

.btn-plan {
    padding: 20px !important;
}

.plan {
    overflow: hidden;
    -webkit-transition: all .1s ease-in-out;
    -moz-transition: all .1s ease-in-out;
    -ms-transition: all .1s ease-in-out;
    -o-transition: all .1s ease-in-out;
    transition: all .1s ease-in-out;
}

.plan:hover {
    -webkit-transform: scale(1.05);
    -moz-transform: scale(1.05);
    -ms-transform: scale(1.05);
    -o-transform: scale(1.05);
    transform: scale(1.05);
    z-index: 1;
}

@media screen and (min-width: 767px) {
    .principal {
        transform: scale(1.05);
    }
    .principal:hover {
        transform: scale(1.1);
        z-index: 1;
    }
}

.plan-ribbon {
    font-size: 15px;
    text-transform: uppercase;
    color: #fff;
    background-color: #21ba45;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 55px;
    min-width: 200px;
    text-align: center;
    position: absolute;
    top: 0px;
    right: -45px;
    -webkit-transform: rotate(40deg);
    -moz-transform: rotate(40deg);
    -ms-transform: rotate(40deg);
    -o-transform: rotate(40deg);
    transform: rotate(45deg);
    -webkit-box-shadow: 1px 1px 8px rgba(0, 0, 0 0.2);
    -moz-box-shadow: 1px 1px 8px rgba(0, 0, 0 0.2);
    box-shadow: 1px 1px 8px rgba(0, 0, 0 0.2);
    z-index: 1;
    line-height: 10px;
}

.plan-ribbon.red {
    background-color: #DB2828;
}

.plan-ribbon.green {
    background-color: #21BA45;
}

.plan-ribbon.yellow {
    background-color: #FBBD08;
}

.amount {
    font-size: 45px;
    line-height: 45px;
    font-weight: 600;
}

.regular-price {
    text-decoration: line-through;
    font-weight: 100;
    font-size: 16px;
    color: rgb(190, 190, 190);
}
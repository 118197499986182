@import url(https://fonts.googleapis.com/css?family=Poppins&display=swap);
#app-sidebar .side {
    background-color: #384EA1
}

#app-sidebar .side .item:hover {
    background-color: #5668AF
}

.ui.inverted.menu .active.item {
    background-color: #5298f29c !important
}

#app-sidebar .side .item {
    border: none;
}

.table-actions .ui.button {
    background-color: #384EA1;
    color: whitesmoke
}

.table-actions .ui.button:hover {
    background-color: #1a3ab3;
    color: whitesmoke
}

.table-actions .ui.button.active {
    background-color: #1a3ab3 !important;
}

.table-actions .ui.button.active.visible {
    background-color: #1a3ab3 !important;
    color: whitesmoke
}
body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


* {
    font-family: 'Poppins', sans-serif;
}

.label, .value {
    font-family: 'Poppins', sans-serif !important;    
}

#application-content {
    padding-left: 0.1rem;
}

i.whitesmoke.icon {
    color: whitesmoke !important;
}

.sidebar.visible + .pusher {
    width: calc(100% - 260px);
}

#support-button {
    position: fixed;
    bottom: 2rem;
    right: 2rem;
}
#app {
    position: absolute;
    height: 100%;
    width: 100%
}

#app-sidebar {
    height: 100%;
}

#app-sidebar .ui.segment {
    border: none;
    border-radius: 0;
}

#app-sidebar .side {
    display: flex;
    justify-content: space-between;
    overflow-x: hidden
}

#app-sidebar .side .logo {
    margin-top: 1.2rem;
}

#app-sidebar .side .greeting {
    text-align: center;
    color: whitesmoke;
}

#app-sidebar .links {
    padding-top: 1.2rem;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    height: 100%;
}

#app-sidebar .side .links .bottom button {
    width: 100%;
    border-radius: 0;
    padding: 1rem;
}

#app-sidebar .side .pusher {
    height: 100%;
}

#app-sidebar .side .toggle-sidebar {
    position: absolute;
    right: 0.5rem;
    top: 0.6rem;
}

#app-sidebar .userInfo {
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 2rem;
    margin-bottom: 1.5rem;
}

#app-sidebar .userInfo h3 {
    margin-bottom: 0.2rem
}

#app-sidebar .toggle-sidebar.after {
    position: fixed;
    left: 0;
    top: 45%;
    padding-left: 0 !important;
    margin: 0 !important;
    background-color: transparent !important;
}

#app-sidebar .side .toggle-sidebar i {
    font-size: 1.4rem;
}

#app-sidebar .toggle-sidebar.after i {
    font-size: 2.5rem;
}

#app-sidebar .side .item {
    text-transform: uppercase
}

#app-sidebar .bottom button {
    text-transform: uppercase
}

footer {
    margin-top: 1.5rem;
    padding: 1rem
}

footer hr {
    border: 0;
    height: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    margin-bottom: 1rem;
}

footer .footer-content {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

footer .footer-content p,footer .footer-content a {
    padding: 0;
    margin: 0
}

footer .footer-content a {
    color: #444;
    text-decoration: underline
}
#users-table .table-actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

#users-table .table-actions .filter {
    margin-right: 0.5rem;
    margin-left: 0.4rem;
}

#users-table .table-actions .filter i {
    margin-left: 0.5rem !important
}

#users-table .table-actions .search-box {
    min-width: 40%;
}

#users-table table button {
    /* font-size: 1.2rem; */
    box-shadow: none !important;
    border: none !important;
    margin: 0.2rem;
    padding: 0;
}

#users-table table button i {
    font-size: 1.2rem
}

.ui.selection.dropdown {
    width: 100%;
}

.image-part {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
#employees-table .table-actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

#employees-table .table-actions .search-box {
    min-width: 40%;
}

#employees-table table button {
    /* font-size: 1.2rem; */
    box-shadow: none !important;
    border: none !important;
    margin: 0.2rem;
    padding: 0;
}

#employees-table table button i {
    font-size: 1.2rem
}

.ui.selection.dropdown {
    width: 100%;
}

.image-part {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
#locations-table .table-actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

#locations-table .table-actions .search-box {
    min-width: 40%;
}

#locations-table table .cell-actions button {
    /* font-size: 1.2rem; */
    box-shadow: none !important;
    border: none !important;
    margin: 0.2rem;
    padding: 0;
}

#locations-table table .cell-actions button i {
    font-size: 1.2rem
}

#locations-table table .cell-actions {
    padding-right: 1rem;
    padding-left: 1rem;
}

#locations-table table .cell-actions button {
    margin-left: 0.5rem;
    margin-right: 0.5rem
}
#roles-table table button {
    /* font-size: 1.2rem; */
    box-shadow: none !important;
    border: none !important;
    margin: 0.2rem;
    padding: 0;
}

#roles-table table button i {
    font-size: 1.2rem
}

#roles-table table .cell-actions {
    padding-right: 1rem;
    padding-left: 1rem;
}

#roles-table table .cell-actions button {
    margin-left: 0.5rem;
    margin-right: 0.5rem
}
#attendance-log-table .table-actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

#attendance-log-table .table-actions .search-box {
    min-width: 40%;
}

#attendance-log-table table button {
    /* font-size: 1.2rem; */
    box-shadow: none !important;
    border: none !important;
    margin: 0.2rem;
    padding: 0;
}

#attendance-log-table table button i {
    font-size: 1.2rem
}


#attendance-log-table .table-actions .filter i {
    margin-left: 0.4rem !important
}
#attendance-log-table .table-actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

#attendance-log-table .table-actions .search-box {
    min-width: 40%;
}

#attendance-log-table table button {
    /* font-size: 1.2rem; */
    box-shadow: none !important;
    border: none !important;
    margin: 0.2rem;
    padding: 0;
}

#attendance-log-table table button i {
    font-size: 1.2rem
}


#attendance-log-table .table-actions .filter i {
    margin-left: 0.4rem !important
}
#attendance-log-table .table-actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

#attendance-log-table .table-actions .search-box {
    min-width: 40%;
}

#attendance-log-table table button {
    /* font-size: 1.2rem; */
    box-shadow: none !important;
    border: none !important;
    margin: 0.2rem;
    padding: 0;
}

#attendance-log-table table button i {
    font-size: 1.2rem
}


#attendance-log-table .table-actions .filter i {
    margin-left: 0.4rem !important
}
#time-sheet-table .table-actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

#time-sheet-table .table-actions .search-box {
    min-width: 40%;
}

#time-sheet-table table button {
    /* font-size: 1.2rem; */
    box-shadow: none !important;
    border: none !important;
    margin: 0.2rem;
    padding: 0;
}

#time-sheet-table table button i {
    font-size: 1.2rem
}


#time-sheet-table .table-actions .filter i {
    margin-left: 0.4rem !important
}
#employee-time-sheet-table .table-actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

#employee-time-sheet-table .table-actions .header {
    min-width: 40%;
}

#employee-time-sheet-table table button {
    /* font-size: 1.2rem; */
    box-shadow: none !important;
    border: none !important;
    margin: 0.2rem;
    padding: 0;
}

#employee-time-sheet-table table button i {
    font-size: 1.2rem
}


#employee-time-sheet-table .table-actions .filter i {
    margin-left: 0.4rem !important
}
#login-section {
    width: 950px;
    height: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center
}

#login-section .photo-section {
    width: 58%;
}

#login-section .login-section {
    width: 40%
}

.login-button:hover {
    background-color: rgb(49, 83, 189) !important;
}
.plan-title {
    font-size: 25px;
    text-align: center;
}

.feature {
    border: none !important;
    font-size: 25px;
    display: flex;
    justify-content: left;
}

.feature i {
    margin-right: 2rem!important;
}

.price {
    text-align: center;
}

.feature .regular-price {
    position: absolute;
    top: 5px;
    right: 49%;
}

.btn-plan {
    padding: 20px !important;
}

.plan {
    overflow: hidden;
    transition: all .1s ease-in-out;
}

.plan:hover {
    -webkit-transform: scale(1.05);
    transform: scale(1.05);
    z-index: 1;
}

@media screen and (min-width: 767px) {
    .principal {
        -webkit-transform: scale(1.05);
                transform: scale(1.05);
    }
    .principal:hover {
        -webkit-transform: scale(1.1);
                transform: scale(1.1);
        z-index: 1;
    }
}

.plan-ribbon {
    font-size: 15px;
    text-transform: uppercase;
    color: #fff;
    background-color: #21ba45;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 55px;
    min-width: 200px;
    text-align: center;
    position: absolute;
    top: 0px;
    right: -45px;
    -webkit-transform: rotate(40deg);
    transform: rotate(45deg);
    box-shadow: 1px 1px 8px rgba(0, 0, 0 0.2);
    z-index: 1;
    line-height: 10px;
}

.plan-ribbon.red {
    background-color: #DB2828;
}

.plan-ribbon.green {
    background-color: #21BA45;
}

.plan-ribbon.yellow {
    background-color: #FBBD08;
}

.amount {
    font-size: 45px;
    line-height: 45px;
    font-weight: 600;
}

.regular-price {
    text-decoration: line-through;
    font-weight: 100;
    font-size: 16px;
    color: rgb(190, 190, 190);
}

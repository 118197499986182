#time-sheet-table .table-actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

#time-sheet-table .table-actions .search-box {
    min-width: 40%;
}

#time-sheet-table table button {
    /* font-size: 1.2rem; */
    box-shadow: none !important;
    border: none !important;
    margin: 0.2rem;
    padding: 0;
}

#time-sheet-table table button i {
    font-size: 1.2rem
}


#time-sheet-table .table-actions .filter i {
    margin-left: 0.4rem !important
}
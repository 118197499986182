#locations-table .table-actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

#locations-table .table-actions .search-box {
    min-width: 40%;
}

#locations-table table .cell-actions button {
    /* font-size: 1.2rem; */
    box-shadow: none !important;
    border: none !important;
    margin: 0.2rem;
    padding: 0;
}

#locations-table table .cell-actions button i {
    font-size: 1.2rem
}

#locations-table table .cell-actions {
    padding-right: 1rem;
    padding-left: 1rem;
}

#locations-table table .cell-actions button {
    margin-left: 0.5rem;
    margin-right: 0.5rem
}
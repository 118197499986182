#app {
    position: absolute;
    height: 100%;
    width: 100%
}

#app-sidebar {
    height: 100%;
}

#app-sidebar .ui.segment {
    border: none;
    border-radius: 0;
}

#app-sidebar .side {
    display: flex;
    justify-content: space-between;
    overflow-x: hidden
}

#app-sidebar .side .logo {
    margin-top: 1.2rem;
}

#app-sidebar .side .greeting {
    text-align: center;
    color: whitesmoke;
}

#app-sidebar .links {
    padding-top: 1.2rem;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    height: 100%;
}

#app-sidebar .side .links .bottom button {
    width: 100%;
    border-radius: 0;
    padding: 1rem;
}

#app-sidebar .side .pusher {
    height: 100%;
}

#app-sidebar .side .toggle-sidebar {
    position: absolute;
    right: 0.5rem;
    top: 0.6rem;
}

#app-sidebar .userInfo {
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 2rem;
    margin-bottom: 1.5rem;
}

#app-sidebar .userInfo h3 {
    margin-bottom: 0.2rem
}

#app-sidebar .toggle-sidebar.after {
    position: fixed;
    left: 0;
    top: 45%;
    padding-left: 0 !important;
    margin: 0 !important;
    background-color: transparent !important;
}

#app-sidebar .side .toggle-sidebar i {
    font-size: 1.4rem;
}

#app-sidebar .toggle-sidebar.after i {
    font-size: 2.5rem;
}

#app-sidebar .side .item {
    text-transform: uppercase
}

#app-sidebar .bottom button {
    text-transform: uppercase
}

footer {
    margin-top: 1.5rem;
    padding: 1rem
}

footer hr {
    border: 0;
    height: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    margin-bottom: 1rem;
}

footer .footer-content {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

footer .footer-content p,footer .footer-content a {
    padding: 0;
    margin: 0
}

footer .footer-content a {
    color: #444;
    text-decoration: underline
}
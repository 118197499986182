@import url('https://fonts.googleapis.com/css?family=Poppins&display=swap');

body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


* {
    font-family: 'Poppins', sans-serif;
}

.label, .value {
    font-family: 'Poppins', sans-serif !important;    
}

#application-content {
    padding-left: 0.1rem;
}

i.whitesmoke.icon {
    color: whitesmoke !important;
}

.sidebar.visible + .pusher {
    width: calc(100% - 260px);
}

#support-button {
    position: fixed;
    bottom: 2rem;
    right: 2rem;
}
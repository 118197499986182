#roles-table table button {
    /* font-size: 1.2rem; */
    box-shadow: none !important;
    border: none !important;
    margin: 0.2rem;
    padding: 0;
}

#roles-table table button i {
    font-size: 1.2rem
}

#roles-table table .cell-actions {
    padding-right: 1rem;
    padding-left: 1rem;
}

#roles-table table .cell-actions button {
    margin-left: 0.5rem;
    margin-right: 0.5rem
}
#employees-table .table-actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

#employees-table .table-actions .search-box {
    min-width: 40%;
}

#employees-table table button {
    /* font-size: 1.2rem; */
    box-shadow: none !important;
    border: none !important;
    margin: 0.2rem;
    padding: 0;
}

#employees-table table button i {
    font-size: 1.2rem
}

.ui.selection.dropdown {
    width: 100%;
}

.image-part {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
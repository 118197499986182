#users-table .table-actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

#users-table .table-actions .filter {
    margin-right: 0.5rem;
    margin-left: 0.4rem;
}

#users-table .table-actions .filter i {
    margin-left: 0.5rem !important
}

#users-table .table-actions .search-box {
    min-width: 40%;
}

#users-table table button {
    /* font-size: 1.2rem; */
    box-shadow: none !important;
    border: none !important;
    margin: 0.2rem;
    padding: 0;
}

#users-table table button i {
    font-size: 1.2rem
}

.ui.selection.dropdown {
    width: 100%;
}

.image-part {
    display: flex;
    justify-content: space-between;
    align-items: center;
}